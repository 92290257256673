import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Сергеева, д. 3',
		place: null,
		phoneNumbers: ['8 (3952) 48-70-07'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinojam.club',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-киноjam/id1292520668?l=ru&ls=1&mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinojam',
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CBUgiBhAwB',
		selectButton: {
			options: [
				{
					value: 'https://kinojam.club',
					title: 'Иркутск',
				},
				{
					value: 'https://kirov.kinojam.club',
					title: 'Киров',
				},
			],
			selectedOptionValue: 'https://kinojam.club',
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};

