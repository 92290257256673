import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 48, 175',
		'primary-dark': '0, 35, 128',
		'accent': '255, 2, 15',
		'accent-plus': '255, 255, 255',
	},
});
